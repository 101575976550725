import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// const guest = (to, from, next) => {
//   localStorage.removeItem("authToken");
//   if (!localStorage.getItem("authToken")) {
//     localStorage.removeItem("authToken");
//     return next(next);
//   } else {
//     return next("/");
//   }
// };

const guest = (to, from, next) => {
  const authToken = localStorage.getItem("authToken");
  const shopifyStoreCount = localStorage.getItem("shopifyStoreCount");


  if (authToken && shopifyStoreCount === 1) {
    return next("/");
  } else {
    return next();
  }
};


const auth = (to, from, next) => {
  const authToken = localStorage.getItem("authToken");
  const permissions = localStorage.getItem("permissions");

  if (authToken && permissions) {
    if (permissions === "1" && to.path !== "/subscriptions") {
      return next("/subscriptions"); // Admin
    } else if (permissions === "2" && to.path === "/login") {
      return next("/orders"); // Regular user
    }
    return next(); // Allow navigation to the requested path
  }

  localStorage.removeItem("authToken");
  return next("/login");
};



const adminAuth = (to, from, next) => {
  if (localStorage.getItem("authToken") && localStorage.getItem("permissions") == 1) {
    return next();
  }
  else {
    localStorage.removeItem("authToken");
    return next("/login");
  }
}; 
const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Auth/Login.vue")
  },
  {
    path: "/register",
    name: "Register",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Register" */ "../views/Auth/Register.vue")
  },
  {
    path: "/add_store",
    name: "AddStore",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Add Store" */ "../views/AddStore.vue")
  },
  {
    path: "/instruction",
    name: "InstructionToAddStore",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Add Store" */ "../views/AddStoreInstruction.vue")
  },
  {
    path: "/confirm-order/:orderId?",
    name: "ConfirmOrder",
    component: () =>
      import(/* webpackChunkName: "Add Store" */ "../views/ConfirmedOrder.vue")
  },
  {
    path: "/orders",
    name: "Orders",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Home.vue")
  },
  {
    path: "/customers",
    name: "Customers",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Customers.vue")
  },
  {
    path: "/RefundOrders",
    name: "RefundOrders",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/RefundOrders.vue")
  },
  {
    path: "/Subscription-detail",
    name: "SubscriptionDetail",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/SubscriptionCard.vue")
  },
  {
    path: "/Subscriptions",
    name: "Subscriptions",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Subscriptions.vue")
  },
  {
    path: "/configuration",
    name: "Configuration",
    beforeEnter: adminAuth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Configuration.vue")
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    beforeEnter: adminAuth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Subscriptions.vue")
  },
  {
    path: "/stores",
    name: "Stores",
    beforeEnter: adminAuth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Stores.vue")
  },
  {
    path: "/adminConfigration",
    name: "AdminConfigration",
    beforeEnter: adminAuth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/TimeConfigration.vue")
  },
  {
    path: "/createSubscriptions",
    name: "CreateSubscriptions",
    beforeEnter: adminAuth,
    component: () =>
      import(/* webpackChunkName: "CreateEventType" */ "../views/CreateSubscriptions.vue"),
  },
  {
    path: "/verification-email",
    name: "VerificationEmail",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Verification_email"*/ "../views/Auth/VerificationEmail.vue"),
  },
  {
    path: "/verified-email",
    name: "VerifiedEmail",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "verified-email" */ "../views/Auth/VerifiedEmail.vue"),
  },
  {
    path: "/verify/:hash",
    name: "Verify",
    // beforeEnter: auth,
    beforeEnter: guest,
    props: true,
    component: () =>
      import(/* webpackChunkName: "Verify" */ "../views/Auth/Verify.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswrod",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/Auth/ForgotPassword/Forgot.vue"
      ),
  },
  
  {
    path: "/verify-email",
    name: "VerifyEmail",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "VerifyEmail" */ "../views/Auth/ForgotPassword/VerifyEmail.vue"
      ),
  },
  {
    path: "/new-password",
    name: "NewPassword",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "NewPassword" */ "../views/Auth/ForgotPassword/NewPassword.vue"
      ),
  },
  {
    path: "/password-update",
    name: "PasswordUpdate",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "PasswordUpdate" */ "../views/Auth/ForgotPassword/PasswordUpdate.vue"
      ),
  },
 
  {
    path: "/notification",
    name: "notification",
    // beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Notificatioins.vue")
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
export default router;